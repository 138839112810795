'use strict';

/**
 * @ngdoc function
 * @name informaApp.directive:infSelect
 * @description
 * # infSelect
 * Directive of the informaApp
 */
angular.module('informaApp')
  .directive('infSelect', function () {

    return{
      restrict: 'E',
      scope:{
        label: '@',
        multiple: '@',
        model: '=',
        enabled: '=enabled',
        query: '=query',
        options: '=options'
      },
      link: function(scope, el, attrs, controllers){
        if(attrs['multiple']){
          //ng-model == query[model]
          if(typeof scope.model == 'string'){console.warn('! disregard warnings:')};
          el.find('select').attr('multiple','multiple');
        }
      },
      templateUrl: 'directives/inf-select/template.ptl.html'
    }

  });